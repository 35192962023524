<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}试验报告
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="staionTestInfo" :rules="rules" ref="staionTestInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" required v-if="state"
                        class="input-lenght">
                        <el-input v-model="staionTestInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="班组工器具编号" :label-width="formLabelWidth" prop="sid" required v-if="state"
                        class="input-lenght">
                        <el-input v-model="staionTestInfo.sid" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="试验类别" :label-width="formLabelWidth" prop="shType" class="input-lenght">
                        <el-select placeholder="请选择试验类别" v-model="staionTestInfo.shType">
                            <el-option v-for="shType in shTypeList" :key="shType.pKey" :label="shType.pValue"
                                :value="shType.pKey"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="试验地点" :label-width="formLabelWidth" prop="location" class="input-lenght">
                        <el-input v-model="staionTestInfo.location" autocomplete="off" placeholder="请输入试验地点"></el-input>
                    </el-form-item>
                    <el-form-item label="试验人员" :label-width="formLabelWidth" prop="tester" class="input-lenght">
                        <el-input v-model="staionTestInfo.tester" autocomplete="off" placeholder="请输入试验人员"></el-input>
                    </el-form-item>
                    <el-form-item label="试验日期" :label-width="formLabelWidth" prop="testDate">
                        <el-date-picker v-model="staionTestInfo.testDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择试验日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="是否合格" :label-width="formLabelWidth" prop="isRegular" class="input-lenght">
                        <el-radio v-model="staionTestInfo.isRegular" :label=0>合格</el-radio>
                        <el-radio v-model="staionTestInfo.isRegular" :label=1>不合格</el-radio>
                    </el-form-item>
                    <el-form-item label="合格便签" :label-width="formLabelWidth" prop="detregularNoteailImg">
                        <div class="bannerbox">
                            <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                </uploadImg>
                            </div>
                            <div class="upload1" v-if="imageView">
                                <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                    :http-request="handleUploadFile">
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">点击上传
                                        <!-- <div class="el-upload__tip" slot="tip">请上传图片</div> -->
                                    </div>
                                </el-upload>
                            </div>

                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="试验报告名称" :label-width="formLabelWidth" prop="labReportName" class="input-lenght">
                        <el-input v-model="staionTestInfo.labReportName" autocomplete="off"></el-input>
                    </el-form-item> -->
                    <el-form-item label="试验报告" :label-width="formLabelWidth" prop="labReportName" class="input-upload">
                        <el-input v-model="staionTestInfo.labReportName" autocomplete="off"
                            placeholder="请选择pdf格式的试验报告文件上传"></el-input>
                        <input type="file" ref="import" style="display: none" @change="uploadIn($event)"  />
                        <el-button type="info" plain @click="handleIn" style="margin-left: 10px;">选择文件</el-button>
                    </el-form-item>
                    <el-form-item label="试验结果描述" :label-width="formLabelWidth" prop="resultDesc" class="input-lenght">
                        <el-input v-model="staionTestInfo.resultDesc" autocomplete="off" placeholder="请输入试验结果描述"></el-input>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset" v-if="!this.id">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import uploadImg from '@/components/UploadImg/index'
import { TestInfoRule } from '@/utils/vaildate.js'
import { uploadFile } from '@/api/file'
import { getShTypes, queryStationToolTest, addStationToolTest, updateStationToolTest } from '@/api/stationTest.js'
export default {
    components: {
        uploadImg
    },
    props: {
        id: {
            type: [String, Number]
        },
        sid: {
            type: [String, Number]
        }
    },
    data() {
        return {
            shTypeList: [],
            staionTestInfo: {
                id: null,
                sid: this.sid,
                shType: null,
                location: null,
                tester: null,
                testDate: null,
                isRegular: 0,
                regularNote: null,
                labReportName: null,
                labReport: null,
                resultDesc: null
            },
            formLabelWidth: "150px",
            rules: TestInfoRule,
            imgsList: [],
            imageView: true,
            path: process.env.VUE_APP_URL + this.UploadPath,
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false
        }
    },
    mounted() {
        console.log(this.sid)
        this.loadShTypes()
        this.id && this.loadStationTestInfo()
    },
    methods: {
        async loadShTypes() {
            await getShTypes().then(res => {
                if (res.code === '000000') {
                    this.shTypeList = res.data
                }
            })
        },
        loadStationTestInfo() {
            queryStationToolTest(this.id).then(res => {
                if (res.code === '000000') {
                    this.staionTestInfo = res.t
                    if (this.staionTestInfo.regularNote != null && this.staionTestInfo.regularNote != '') {
                        const obj = {
                            imageName: this.staionTestInfo.regularNote
                        }
                        console.log("this.staionTestInfo")
                        console.log(this.staionTestInfo)
                        this.imgsList.push(obj)
                        this.imageView = false

                    }
                    // this.staionTestInfo.sid = this.sid
                }
            })
        },


        //提交
        submitForm() {
            this.$refs.staionTestInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    this.staionTestInfo.regularNote = '';
                    this.imgsList.forEach(item => {
                        this.staionTestInfo.regularNote = (item.imageName)
                    })
                    if (this.id) {
                        updateStationToolTest(this.staionTestInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addStationToolTest(this.staionTestInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        //重置
        handleReset() {
            this.$refs.staionTestInfo.resetFields()
            this.imgsList = []
            this.imageView = true
        },

        //上传文件
        //点击导入
        handleIn() {
            this.$refs.import.click();
        },
        //上传文件
        uploadIn(event) {
            console.log('上传文件')
            let file = event.target.files; //获得文件
            for (let i = 0; i < file.length; i++) {
                //    上传类型判断
                let fileName = file[i].name;
                let idx = fileName.lastIndexOf(".");
                if (idx != -1) {
                    let ext = fileName.substr(idx + 1).toUpperCase();
                    ext = ext.toLowerCase();
                    if (ext !== "pdf") {
                        this.$message.warning('上传文件只能是pdf文件!')
                        return;
                    } else {
                        this.importFile = file[i];
                    }
                }
            }
            let formData = new FormData(); //数据
            formData.append("file", this.importFile);
            uploadFile(formData)
                .then(res => {
                    if (res.code === '000000') {
                        this.staionTestInfo.labReport = res.data.importFileName
                        this.staionTestInfo.labReportName = res.data.fileName
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },

        //上传图片
        handleUploadFile(file) {
            const formData = new FormData()
            formData.append('file', file.file)

            uploadFile(formData).then(res => {
                if (res.code === '000000') {
                    this.imageUrl = res.data.importFileName
                    const obj = {
                        imageName: res.data.importFileName
                    }
                    this.imgsList.push(obj)
                    // this.$emit('imgVal', res.data.path)
                    this.staionTestInfo.regularNote = '';
                    this.imgsList.forEach(item => {
                        this.staionTestInfo.regularNote = (item.imageName)
                    })
                    if (this.imgsList.length > 0) {
                        this.imageView = false;
                    }

                }
            })
        },
        handleDel(item, index) {
            this.imgsList.splice(index, 1)
            this.imageView = true
        },
        // 图片上传后地址
        imgVal(val, item) {
            item.imageName = val
        },
    }
}
</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        width: 600px;
        text-align: left;
    }

    .input-lenght {
        width: 500px;

    }

    .input-upload {
        width: 500px;
        position: relative;

        .el-button {
            position: absolute;
            top: 0px;
            right: -100px;
        }
    }

    .upload1 {
        width: 360px;
        height: 180px;
        margin-right: 20px;
        margin-bottom: 20px;

        .el-upload-dragger,
        .el-upload--text,
        .upload-demo1 {
            width: 100%;
            height: 100%;
        }

        .el-upload-dragger .el-icon-upload {
            margin-top: 60px;
        }

        .el-upload__text {
            margin-top: 5px;
        }
    }

    .bannerbox {
        display: flex;
        flex-wrap: wrap;
    }

    .banners {
        width: 360px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        .cha {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            z-index: 2;
        }

        .el-upload__tip {
            margin-top: 7px;
        }

        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            border: none;
            border-radius: 0;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .btns {
        margin-top: 50px;
    }

    .upload-demo {
        width: 360px;
        height: 200px;
    }

    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }


}
</style>